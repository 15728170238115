<template>
  <b-modal
    id="rental-company-edit-modal"
    title="Company Edit Modal"
    centered
    hide-footer
    size="lg"
    @hidden="reset"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template #modal-title>
      <h2 class="m-0">Company Edit</h2>
    </template>
    <validation-observer ref="rentalCompanyEditFormValidation">
      <b-form @submit.prevent="submit">
        <b-form-group label-for="name">
          <template #label>
            Company Name <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="name"
            v-model="name"
            v-restrict="regex"
            required
            placeholder="Company Name"
            @keydown="checkMaxLength($event, 'name', 200)"
          />
        </b-form-group>

        <b-form-group label-for="description">
          <template #label> Description </template>
          <quill-editor id="description" v-model="description" />
        </b-form-group>

        <b-form-group label-for="status">
          <template #label>
            Status <span class="text-danger">*</span>
          </template>
          <b-form-select
            id="status"
            v-model="status"
            :options="options"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group class="mt-2 text-right">
          <b-button type="submit" variant="info" pill class="mr-1">
            Update
          </b-button>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  mixins: [util],
  props: {
    rentalCompany: Object,
  },
  data() {
    return {
      required,
      name: "",
      description: "",
      status: "",
      options: [
        { value: "", text: "-- Select --" },
        { value: true, text: "Enable" },
        { value: false, text: "Disable" },
      ],
      regex: /^[a-zA-Z ]*$/,
    };
  },
  async mounted() {
    if (this.rentalCompany) {
      this.name = this.rentalCompany.name;
      this.description = this.rentalCompany.description;
      this.status = this.rentalCompany.status;
    }
  },
  methods: {
    ...mapActions({
      updateRentalCompany: "appData/updateRentalCompany",
    }),
    checkMaxLength(event, fieldName, maxLength) {
      if (
        event.target.value.length >= maxLength &&
        event.key !== "Backspace" &&
        event.key !== "Delete"
      ) {
        event.preventDefault();
        this[fieldName] = event.target.value.slice(0, maxLength);
      }
    },
    async submit() {
      try {
        const res = await this.updateRentalCompany({
          payload: {
            name: this.name,
            description: this.description,
            status: this.status,
            updated_by: this.getLoggedInUser.id,
          },
          pk: this.rentalCompany.id,
        });
        if (res.status === 200) {
          this.$swal({
            title: "Rental Company updated successfully",
            icon: "success",
          });
          this.reset();
          this.$nextTick(() => {
            this.$bvModal.hide("rental-company-edit-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    reset() {
      this.name = "";
      this.description = "";
      this.status = "";
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
  },
};
</script>

<style></style>
